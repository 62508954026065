<template>
    
</template>

<script>

import * as DOMPurify from 'dompurify';


///save url parameters in localstorage -- scan reports juno - userapikey, scandomain,scanid
//fetch url params
let urlParams = new URLSearchParams(window.location.search);
let userapikey = urlParams.get('userapikey');
let scandomain = urlParams.get('scandomain');
let scanid = urlParams.get('scanid');

let userapikeysanitized = DOMPurify.sanitize(userapikey);
let scandomainsanitized = DOMPurify.sanitize(scandomain);
let scanidsanitized = DOMPurify.sanitize(scanid);




//set values in localstorage if urlparams is not null
if (urlParams.has('userapikey') && urlParams.has('scandomain') &&  urlParams.has('scanid') ) {
  
  
  //checks if theme value in localstorage is empty
  //if empty sets the theme color as dark
  // else stores the current theme color
  //clear all localstorage keys and values
  //then sets the old theme color as new localstorage value
  

  var securityinfinitydashboardthemeval = localStorage.getItem('securityinfinitydashboard-skin');

  if (securityinfinitydashboardthemeval === null) {
    localStorage.setItem('securityinfinitydashboard-skin',"dark")
  } else {
    localStorage.clear();
    localStorage.setItem('securityinfinitydashboard-skin',securityinfinitydashboardthemeval);

  }

  

  //localStorage.removeItem('userapikey');
  //localStorage.removeItem('scandomain');
  //localStorage.removeItem('scanid');

  localStorage.setItem('userapikey', userapikeysanitized)
  localStorage.setItem('scandomain',  scandomainsanitized)
  localStorage.setItem('scanid', scanidsanitized)
}


export default {
    }
</script>