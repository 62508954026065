<template>
  <div>
 
  </div>
</template>

<script>
import { BCard, BCardText, BLink } from 'bootstrap-vue'
import JunoAuth from '././juno_auth/auth.vue'


export default {
  components: {
    BCard,
    BCardText,
    BLink,
    JunoAuth

  },
}
</script>

<style>

</style>
